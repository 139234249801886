.home-container-1{
    min-height: 100vh;
    max-width: 1250px;
    width: 100%;
    display: flex;
    margin: 0% auto;
}

.home-container-2{
    max-width: 1100px;
    width: calc(100% - 164px);
    padding: 24px;
    box-sizing: border-box;
}

@media (max-width:768px) {
    .home-container-2{
        width: calc(100%);
        padding: 10px;
        box-sizing: border-box;
    }
}